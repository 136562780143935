import React from 'react';
import {Text, IconToggle} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {classes} from './BottomFoldableSummarySection.st.css';
import ChevronUp from 'wix-ui-icons-common/on-stage/ChevronUp';
import ChevronDown from 'wix-ui-icons-common/on-stage/ChevronDown';

export enum BottomFoldableSummarySectionDataHooks {
  root = 'BottomFoldableSummarySectionDataHooks.root',
  content = 'BottomFoldableSummarySectionDataHooks.content',
  chevronIcon = 'BottomFoldableSummarySectionDataHooks.chevronIcon',
}

export interface BottomFoldableSummarySectionProps {
  onClick: () => void;
  isOpen: boolean;
}

export const BottomFoldableSummarySection = ({onClick, isOpen}: BottomFoldableSummarySectionProps) => {
  const localeKeys = useLocaleKeys();
  const {
    checkoutStore: {checkout},
  } = useControllerProps();
  return (
    <div
      data-hook={BottomFoldableSummarySectionDataHooks.root}
      className={classes.root}
      onClick={onClick}
      role={'button'}
      tabIndex={0}
      onKeyDown={onClick}>
      <Text data-hook={BottomFoldableSummarySectionDataHooks.content}>
        {isOpen
          ? localeKeys.checkout.mobile.hideItems.label({number: checkout.numberOfItems})
          : localeKeys.checkout.mobile.showItems.label({number: checkout.numberOfItems})}
      </Text>
      <IconToggle
        data-hook={BottomFoldableSummarySectionDataHooks.chevronIcon}
        icon={<ChevronDown />}
        checkedIcon={<ChevronUp />}
        checked={isOpen}
      />
    </div>
  );
};
