import React from 'react';
import {classes} from './Summary.st.css';
import {useControllerProps} from '../Widget/ControllerContext';
import {TotalsSection} from './TotalsSection/TotalsSection';
import {SecureCheckout} from './SecureCheckout/SecureCheckout';
import {SlotId} from '../../../domain/utils/slotId';
import {CheckoutSlot} from '../CheckoutSlot';
import {CouponInput} from './CouponInput/CouponInput';
import {GiftCardInput} from './GiftCardInput/GiftCardInput';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../constants';
import {StepId, SummaryLocation, ViolationsAndPlaceOrderButtonLocation} from '../../../types/app.types';
import {ViolationsAndPlaceOrderButton} from '../ViolationsAndPlaceOrderButton/ViolationsAndPlaceOrderButton';
import {SummaryTitle} from './SummaryTitle/SummaryTitle';
import {LineItemSection} from './LineItemSection/LineItemSection';
import {TopMobileSummary} from './TopMobileSummary';

export enum SummaryDataHooks {
  couponSectionInput = 'SummaryDataHooks.couponSectionInput',
  giftCardSectionInput = 'SummaryDataHooks.giftCardSectionInput',
  violations = 'SummaryDataHooks.violations',
}

export interface SummaryProps {
  location: SummaryLocation;
  dataHook: string;
  checkboxesRef?: React.RefObject<HTMLDivElement>;
}

// eslint-disable-next-line sonarjs/cognitive-complexity
export const Summary = ({dataHook, location, checkboxesRef}: SummaryProps) => {
  const {
    checkoutStore: {shouldShowGiftCardSection, shouldShowCouponSection, isFastFlow},
    stepsManagerStore: {activeStep},
    isOneColumnView,
  } = useControllerProps();
  const {experiments} = useExperiments();

  const shouldShowViolationsAndPlaceOrderButton =
    isFastFlow || activeStep.stepId === StepId.placeOrder || activeStep.stepId === StepId.paymentAndPlaceOrder;

  const addSlotToCheckout = !isFastFlow && experiments.enabled(SPECS.AddSlotToCheckout);

  let className = classes.stickyOld;

  if (experiments.enabled(SPECS.MergePaymentAndPlaceOrderSteps) && !isFastFlow) {
    className = location === SummaryLocation.paymentAndPlaceOrderStep ? classes.rootMobile : classes.root;
  }

  const shouldSupportNewMobileSummaryBehavior =
    experiments.enabled(SPECS.SupportNewMobileSummaryBehavior) && isOneColumnView;

  return (
    <div
      className={experiments.enabled(SPECS.SupportNewMobileSummaryBehavior) ? classes.sticky : className}
      data-hook={dataHook}>
      {shouldSupportNewMobileSummaryBehavior ? (
        <TopMobileSummary />
      ) : (
        <>
          {addSlotToCheckout && (
            /* istanbul ignore next: test slot */ <CheckoutSlot
              className={classes.BeforeSummaryCheckoutSlot}
              slotId={SlotId.BeforeSummaryCheckoutSlot}
            />
          )}
          <div className={classes.summary}>
            <SummaryTitle />
            <LineItemSection />
            {addSlotToCheckout && (
              /* istanbul ignore next: test slot */ <CheckoutSlot
                className={classes.AfterLineItemsSummaryCheckoutSlot}
                slotId={SlotId.AfterLineItemsSummaryCheckoutSlot}
              />
            )}
            {addSlotToCheckout && (
              /* istanbul ignore next: test slot */ <CheckoutSlot
                className={classes.AfterLineItems2SummaryCheckoutSlot}
                slotId={SlotId.AfterLineItems2SummaryCheckoutSlot}
              />
            )}
            <div className={classes.couponAndGiftCard}>
              {shouldShowCouponSection && <CouponInput />}
              {shouldShowGiftCardSection && <GiftCardInput />}
            </div>
            {addSlotToCheckout && (
              /* istanbul ignore next: test slot */ <CheckoutSlot
                className={classes.BeforeTotalsBreakdownSummaryCheckoutSlot}
                slotId={SlotId.BeforeTotalsBreakdownSummaryCheckoutSlot}
              />
            )}
            <TotalsSection />
            {shouldShowViolationsAndPlaceOrderButton && (
              <ViolationsAndPlaceOrderButton
                location={ViolationsAndPlaceOrderButtonLocation.summary}
                checkboxesRef={checkboxesRef}
              />
            )}
          </div>
          <SecureCheckout />
          {addSlotToCheckout && (
            /* istanbul ignore next: test slot */ <CheckoutSlot
              className={classes.AfterSummaryCheckoutSlot}
              slotId={SlotId.AfterSummaryCheckoutSlot}
            />
          )}
        </>
      )}
    </div>
  );
};
