import {LineItemModel} from '../../../../domain/models/LineItem.model';
import {SubscriptionModel} from '../../../../domain/models/Subscription.model';
import {ViolationModel, ViolationSeverity} from '../../../../domain/models/Violation.model';
import {
  ComWixEcommerceCatalogSpiApiV1PaymentOptionType,
  WixPaymentApiPayV2SubscriptionFrequency,
} from '../../../../gql/graphql';
import {ILocaleKeys} from '../../../../locale-keys/LocaleKeys';

export function getSubscriptionData(
  subscription: SubscriptionModel,
  localeKeys: ILocaleKeys
): {name: string; duration: string} {
  if (subscription.isAutoRenewal) {
    return {
      name: subscription.name,
      duration: localeKeys.checkout.page_plan_autorenew(),
    };
  }

  const frequency = getFrequencyText(subscription.frequency, subscription.billingCycles, localeKeys);

  if (subscription.billingCycles > 1) {
    return {
      name: subscription.name,
      duration: localeKeys.checkout.page_plan_duration_description_plural({
        numberOfFrequencyUnits: subscription.billingCycles,
        frequencyUnitSingular: frequency,
      }),
    };
  }

  return {
    name: subscription.name,
    duration: localeKeys.checkout.page_plan_duration_description_singular({
      duration: undefined,
      frequencyUnitSingular: frequency,
    }),
  };
}

function getFrequencyText(
  frequency: WixPaymentApiPayV2SubscriptionFrequency,
  billingCycles: number,
  localeKeys: ILocaleKeys
) {
  switch (frequency) {
    case WixPaymentApiPayV2SubscriptionFrequency.DAY: {
      return billingCycles > 1 ? localeKeys.checkout.page_plan_days() : localeKeys.checkout.page_plan_day();
    }
    case WixPaymentApiPayV2SubscriptionFrequency.WEEK: {
      return billingCycles > 1 ? localeKeys.checkout.page_plan_weeks() : localeKeys.checkout.page_plan_week();
    }
    case WixPaymentApiPayV2SubscriptionFrequency.MONTH: {
      return billingCycles > 1 ? localeKeys.checkout.page_plan_months() : localeKeys.checkout.page_plan_month();
    }
    case WixPaymentApiPayV2SubscriptionFrequency.YEAR: {
      return billingCycles > 1 ? localeKeys.checkout.page_plan_years() : localeKeys.checkout.page_plan_year();
    }
  }
}

export function getPaymentTypeLabel(lineItem: LineItemModel, localeKeys: ILocaleKeys): string {
  const membershipName = lineItem.selectedMembership?.name?.original;

  switch (lineItem.paymentOption) {
    case ComWixEcommerceCatalogSpiApiV1PaymentOptionType.DEPOSIT_ONLINE:
      return localeKeys.checkout.myCartPaymentMethod.deposit.lineItem({price: lineItem.depositAmount?.formattedAmount});
    case ComWixEcommerceCatalogSpiApiV1PaymentOptionType.MEMBERSHIP:
      return membershipName
        ? localeKeys.checkout.myCartPaymentMethod.onlinePlan.lineItem({membership: membershipName})
        : localeKeys.checkout.myCartPaymentMethod.payWithPlan.lineItem();
    case ComWixEcommerceCatalogSpiApiV1PaymentOptionType.MEMBERSHIP_OFFLINE:
      return membershipName
        ? localeKeys.checkout.myCartPaymentMethod.offlinePlan.lineItemWithId({membership: membershipName})
        : localeKeys.checkout.myCartPaymentMethod.offlinePlan.lineItemWithoutId();
    case ComWixEcommerceCatalogSpiApiV1PaymentOptionType.FULL_PAYMENT_OFFLINE:
      return lineItem.prices.lineItemPrice.amount > 0
        ? localeKeys.checkout.myCartPaymentMethod.offlinePayment.lineItem()
        : '';
    default:
      return '';
  }
}

export function isLineItemErrorViolationExist(violations: ViolationModel[]): boolean {
  return violations.some(
    (violation) => !!violation.target?.lineItemTarget && violation.severity === ViolationSeverity.error
  );
}
export function getLineItemViolationIfItExists(
  violations: ViolationModel[],
  lineItemId: string
): ViolationModel | undefined {
  return (
    violations.find(
      (violation) =>
        violation.target?.lineItemTarget?.id === lineItemId && violation.severity === ViolationSeverity.error
    ) ??
    violations.find(
      (violation) =>
        violation.target?.lineItemTarget?.id === lineItemId && violation.severity === ViolationSeverity.warning
    )
  );
}
