import React, {useEffect, useState} from 'react';
import GiftSmall from 'wix-ui-icons-common/on-stage/GiftSmall';
import {SummaryDataHooks} from '../Summary';
import {CodeInputSection} from '../CodeInputSection/CodeInputSection';
import {ILocaleKeys, useLocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {useControllerProps} from '../../Widget/ControllerContext';
import {CheckoutErrorModel} from '../../../../domain/models/CheckoutError.model';
import {CheckoutErrorCode} from '../../../../domain/utils/errors';

export const GiftCardInput: React.FC<{mobilePosition?: string}> = ({mobilePosition}) => {
  const localeKeys = useLocaleKeys();
  const {checkout, applyGiftCard, removeGiftCard, applyGiftCardError, onAddGiftCardSectionOpen} =
    useControllerProps().checkoutStore;
  const [giftCardCodeError, setGiftCardCodeError] = useState(
    applyGiftCardError ? getErrorTextFromGiftCardError(applyGiftCardError, localeKeys) : undefined
  );

  useEffect(() => {
    const error = applyGiftCardError ?? checkout.errors?.giftCardError;
    setGiftCardCodeError(error ? getErrorTextFromGiftCardError(error, localeKeys) : undefined);
  }, [checkout, applyGiftCardError, localeKeys]);

  const handleCodeInputOpen = () => {
    onAddGiftCardSectionOpen(mobilePosition);
  };

  const handleApplyGiftCard = (giftCardCode: string) => {
    void applyGiftCard(giftCardCode, mobilePosition);
  };

  const handleRemoveCode = () => {
    void removeGiftCard(mobilePosition);
  };

  return (
    <CodeInputSection
      dataHook={SummaryDataHooks.giftCardSectionInput}
      enterACodeButtonText={localeKeys.checkout.paymentDetails.redeemGiftCard.checkbox.label()}
      onCodeInputOpen={handleCodeInputOpen}
      applyCodeText={localeKeys.checkout.paymentDetails.enterGiftCard.CTA()}
      removeCodeText={localeKeys.checkout.paymentDetails.enterGiftCard.remove()}
      codeValue={checkout.giftCard?.obfuscatedCode}
      onApplyCode={handleApplyGiftCard}
      onRemoveCode={handleRemoveCode}
      error={giftCardCodeError}
      icon={<GiftSmall />}
      placeholder={localeKeys.checkout.paymentDetails.enterGiftCard.placeholder()}
    />
  );
};

const getErrorTextFromGiftCardError = (applyGiftCardError: CheckoutErrorModel, localeKeys: ILocaleKeys): string => {
  switch (applyGiftCardError?.code) {
    case CheckoutErrorCode.GIFT_CARD_EXPIRED:
      return localeKeys.checkout.paymentDetails.enterGiftCard.error.cardExpired();
    case CheckoutErrorCode.GIFT_CARD_DISABLED:
      return localeKeys.checkout.paymentDetails.enterGiftCard.error.cardDisabled();
    case CheckoutErrorCode.GIFT_CARD_IS_EMPTY:
      return localeKeys.checkout.paymentDetails.enterGiftCard.error.noBalance();
    case CheckoutErrorCode.GIFT_CARD_SERVICE_UNAVAILABLE:
      return localeKeys.checkout.paymentDetails.enterGiftCard.error.serviceDown.inline();
    case CheckoutErrorCode.GIFT_CARD_CURRENCY_INVALID:
      return localeKeys.checkout.paymentDetails.enterGiftCard.error.wrongCurrency();
    case CheckoutErrorCode.GIFT_CARD_CODE_INVALID:
      return localeKeys.checkout.paymentDetails.enterGiftCard.error.invalidCardNumber();
    case CheckoutErrorCode.GIFT_CARD_GENERAL_ERROR:
    case CheckoutErrorCode.GIFT_CARD_UNSUPPORTED_STATUS:
    case CheckoutErrorCode.GIFT_CARD_INSUFFICIENT_BALANCE:
    default:
      return localeKeys.checkout.paymentDetails.enterGiftCard.error.genericFallback.$value();
  }
};
