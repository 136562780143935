import {FormValues} from '@wix/form-viewer';
import {MultilineAddressValue} from '@wix/form-fields';
import {CheckoutSettingsModel} from '../../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {AddressModel} from '../../../../domain/models/Address.model';
import {DEFAULT_COUNTRY, GROO_DEFAULT_COUNTRY, SPECS} from '../../constants';
import {CashierMandatoryFieldsOverrides} from '../../../../types/app.types';
import {Experiments} from '@wix/yoshi-flow-editor';
import {ApiAddressFragment} from '../../../../gql/graphql';

export const getAddressFormOverrides = ({
  experiments,
  checkoutSettings,
  overrides,
  checkoutComposerEnabled,
  isGroo,
  shouldFilterByShippingCountries,
}: {
  experiments?: Experiments;
  checkoutSettings: CheckoutSettingsModel;
  overrides?: CashierMandatoryFieldsOverrides;
  checkoutComposerEnabled: boolean;
  isGroo?: boolean;
  shouldFilterByShippingCountries?: boolean;
}) => {
  let country = {};
  const enabledDeliveryCountries = checkoutSettings.enabledDeliveryCountries;
  if (shouldFilterByShippingCountries) {
    /* istanbul ignore else */
    if (enabledDeliveryCountries?.length) {
      country = {country: {countries: enabledDeliveryCountries}};
    }
  } else {
    /* istanbul ignore next */
    // eslint-disable-next-line no-lonely-if
    if (isGroo) {
      country = {country: {hidden: true, required: false}};
    }
  }
  return {
    address: {
      ...country,
      address_line: {
        /* istanbul ignore next: test forms */ autocomplete: !experiments?.enabled(
          SPECS.CheckoutExcludeGoogleAutoComplete
        ),
        ...(overrides?.address ? {hidden: false, required: true} : {}),
      },
      street_name: {
        /* istanbul ignore next: test forms */ autocomplete: !experiments?.enabled(
          SPECS.CheckoutExcludeGoogleAutoComplete
        ),
        ...(overrides?.street ? {hidden: false, required: true} : {}),
      },
      street_number: {
        ...(overrides?.houseNumber ? {hidden: false, required: true} : {}),
      },
      postal_code: {
        ...(overrides?.zipCode || checkoutSettings.isLocalDeliveryByZipCodeEnabled
          ? {hidden: false, required: true}
          : {}),
      },
      address_line_2: checkoutComposerEnabled
        ? {}
        : {
            hidden: !checkoutSettings.addressLine2.show,
            required: checkoutSettings.addressLine2.mandatory,
          },
    },
  };
};

export const getAddressFormInitialState = (
  checkoutSettings: CheckoutSettingsModel,
  checkoutComposerEnabled: boolean,
  address?: AddressModel,
  isGroo?: boolean,
  shouldFilterByShippingCountries?: boolean
  // eslint-disable-next-line sonarjs/cognitive-complexity
): FormValues => {
  const subdivision = address?.subdivision?.includes('-') ? address?.subdivision?.split('-')[1] : address?.subdivision;
  let defaultCountrySelection;
  const enabledDeliveryCountries = checkoutSettings.enabledDeliveryCountries;
  if (shouldFilterByShippingCountries) {
    if (!enabledDeliveryCountries?.length || enabledDeliveryCountries.includes(DEFAULT_COUNTRY)) {
      defaultCountrySelection = address?.country ?? DEFAULT_COUNTRY;
    } else {
      defaultCountrySelection = enabledDeliveryCountries[0];
    }
  } else {
    /* istanbul ignore next */
    // eslint-disable-next-line no-lonely-if
    if (isGroo) {
      defaultCountrySelection = GROO_DEFAULT_COUNTRY;
    } else {
      defaultCountrySelection = address?.country ?? DEFAULT_COUNTRY;
    }
  }
  return {
    address: {
      country: defaultCountrySelection,
      city: address?.city ?? '',
      ...(subdivision ? {subdivision} : {}),
      ...(address?.postalCode ? {postal_code: address.postalCode} : {}),
      ...(address?.addressLine ? {address_line: address.addressLine} : {}),
      ...(address?.streetAddress?.name ? {street_name: address.streetAddress.name} : {}),
      ...(address?.streetAddress?.number ? {street_number: address.streetAddress.number} : {}),
      ...((checkoutComposerEnabled || checkoutSettings.addressLine2.show) && address?.addressLine2
        ? {address_line_2: address?.addressLine2}
        : {}),
    },
  };
};

export const getAddressFromAddressFormValues = (
  checkoutSettings: CheckoutSettingsModel,
  addressFormValues: FormValues,
  checkoutComposerEnabled: boolean
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ApiAddressFragment => {
  const address = addressFormValues.address as MultilineAddressValue;
  const streetAddress =
    address.street_name || address.street_number
      ? {
          name: (address.street_name as string) || /* istanbul ignore next: test forms */ undefined,
          number: (address.street_number as string) || /* istanbul ignore next: test forms */ undefined,
        }
      : undefined;

  return {
    country: address.country as string,
    city: address.city as string,
    ...(address.subdivision
      ? {
          subdivision: `${address.country as string}-${address.subdivision}`,
        }
      : {}),
    ...(address.postal_code ? {postalCode: address.postal_code} : /* istanbul ignore next: test forms */ {}),
    ...(address.address_line ? {addressLine: address.address_line} : {}),
    ...(streetAddress ? {streetAddress} : {}),
    ...((checkoutComposerEnabled || checkoutSettings.addressLine2.show) && address.address_line_2
      ? {addressLine2: address.address_line_2}
      : {}),
  };
};

export const getCountryFromAddressFormValues = (addressFormValues: FormValues): string => {
  const address = addressFormValues.address as MultilineAddressValue;
  return address.country as string;
};

export const isSubdivisionsEqual = (subdivision1?: string | null, subdivision2?: string | null): boolean => {
  if (subdivision2 && subdivision1) {
    return subdivision1.includes(subdivision2) || /* istanbul ignore next */ subdivision2.includes(subdivision1);
  }
  return subdivision1 === subdivision2;
};
